import styled from "styled-components"
import { colors, fontSizes } from "../../modules/structure/theme"

export default styled.span`
  background-color: ${colors(`primary`)};
  color: white;
  width: 50px;
  height: 50px;
  display: inline-block;
  line-height: 50px;
  text-align: center;
  font-weight: 900;
  font-size: ${fontSizes(`xxx_large`)};
  border-radius: 50%;
  position: relative;

  &:after,
  &:before {
    top: 50%;
    left: 50%;
    content: "";
    width: 10px;
    height: 25px;
    position: absolute;
    background-color: white;
  }

  &:before {
    transform: translateX(-50%) translateY(-50%) rotate(45deg);
  }

  &:after {
    transform: translateX(-50%) translateY(-50%) rotate(-45deg);
  }
`
