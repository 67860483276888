import React from "react"
import styled from "styled-components"
import { Primary } from "../structure/theme/titles.component"
import Stepper from "../tunnel/stepper.component"
import { ButtonLink } from "../structure/theme/button.component"
import { useViewPageTracking } from "../thirdParties/gtm/tagManager"
import { useTranslation } from "../translation/translate.component"
import { tunnelCustomerSteps } from "../tunnel/customer/tunnelCustomer.component"
import Fail from "../../components/pictos/fail.component"
import Block from "../../components/block/block.component"
import { flexCenter } from "../structure/theme/styles.component"

const CenterCard = styled.div`
  ${flexCenter};
`

export default function PaymentFailure () {
  const { t } = useTranslation()

  useViewPageTracking(`TunnelCommande`, ``, `Tunnel - Erreur Paiement`)

  return (
    <Block>
      <Stepper steps={tunnelCustomerSteps} currentStep={3} />
      <CenterCard>
        <Fail />
      </CenterCard>
      <Primary center>{t(`common:payment.section_payment_failure`)}</Primary>
      <CenterCard>
        <ButtonLink route="paymentStep4" data-testid="back_to_step4">
          {t(`common:payment.section_payment_failure_button`)}
        </ButtonLink>
      </CenterCard>
    </Block>
  )
}
